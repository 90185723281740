import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import ParticipateBayerTrial from '../components/participate-bayer-trial/pbt';
import YoutubeVideoPlayer from '../components//video-player/video-player';
import Modal from '../components/modal/modal';
import Redirect from '../components/redirect/redirect';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Row, Col } from 'reactstrap';
import { IoMdArrowDropright } from 'react-icons/io';
import { Fonts, Container, ParticipatePage } from '../global.css';

class WhyParticipate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hideVideo: true,
      modalOpen: false,
    };
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }
  showModal() {
    this.setState({
      modalOpen: true,
    });
  }

  hideModal() {
    this.setState({
      modalOpen: false,
    });
  }
  videoBtnClick = () => {
    this.setState({
      hideVideo: false,
    });
  };

  render() {
    let { data } = this.props;
    return (
      <Layout meta={data.whyParticipateEnJson.seo}>
        <Fonts>
          <ParticipatePage>
            <Container>
              <h2 className={'main-title'}>
                {data.whyParticipateEnJson.participateTitle}
              </h2>
              <Row>
                <Col className="banner-col">
                  <div className={'banner-container'}>
                    {this.state.hideVideo ? (
                      <div className={'banner'}>
                        <Img
                          className={'banner-left'}
                          fluid={
                            data.whyParticipateEnJson.participateImg
                              .childImageSharp.fluid
                          }
                          alt={data.whyParticipateEnJson.participateAlt}
                        />
                        <div className={'banner-right'}>
                          <h3>
                            {data.whyParticipateEnJson.participateSecTitle}
                          </h3>
                          <button
                            className={'video-btn'}
                            onClick={this.videoBtnClick}
                          >
                            {data.whyParticipateEnJson.videoBtn}
                            <span>
                              <IoMdArrowDropright />
                            </span>
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className={'banner-video'}>
                        <YoutubeVideoPlayer
                          title={data.whyParticipateEnJson.participateTitle}
                          width="100%"
                          height="100%"
                          code={data.whyParticipateEnJson.videoCodeYT}
                        />
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
              <h2 className={'main-title'}>
                {data.whyParticipateEnJson.benefitTitle}
              </h2>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    data.whyParticipateEnJson.benefitText.childMarkdownRemark
                      .html,
                }}
              />
              <Img
                className="benefit-img-desktop"
                fluid={
                  data.whyParticipateEnJson.benefitImg.childImageSharp.fluid
                }
                alt={data.whyParticipateEnJson.benefitTitle}
              />
              <Row className="benefit-mobile-sec">
                <Col xs={12} className="benefit-item">
                  <h2>{data.whyParticipateEnJson.benefitTabletSubtitle}</h2>
                </Col>
                {data.whyParticipateEnJson.benefitTabletSection.map(
                  (item, index) => (
                    <Col xs={12} key={index} className="benefit-item">
                      <Img
                        className="benefit-img-tablet"
                        fluid={item.image.childImageSharp.fluid}
                        alt={item.title}
                      />
                      <h3>{item.title}</h3>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.content.childMarkdownRemark.html,
                        }}
                      />
                    </Col>
                  )
                )}
              </Row>
              <h2 className={'main-title'}>
                {data.whyParticipateEnJson.storiesTitle}
              </h2>
              <div
                className="stories-text"
                dangerouslySetInnerHTML={{
                  __html:
                    data.whyParticipateEnJson.storiesText.childMarkdownRemark
                      .html,
                }}
              />
              <h4>{data.whyParticipateEnJson.personalStories}</h4>
              <Row>
                <Col xs={12} className="stories-sec">
                  <div>
                    <Img
                      className={'stories-img'}
                      fluid={
                        data.whyParticipateEnJson.storiesImg.childImageSharp
                          .fluid
                      }
                      alt={data.whyParticipateEnJson.storiesAlt}
                    />
                    <Modal
                      open={this.state.modalOpen}
                      type="redirect"
                      showModal={() => this.showModal()}
                      hideModal={() => this.hideModal()}
                      buttonText={data.whyParticipateEnJson.learnMore}
                      arrowRight={true}
                    >
                      <Redirect
                        hideModal={this.hideModal}
                        url={data.whyParticipateEnJson.storiesExternalLink}
                      />
                    </Modal>
                  </div>
                </Col>
              </Row>
              <ParticipateBayerTrial />
            </Container>
          </ParticipatePage>
        </Fonts>
      </Layout>
    );
  }
}

WhyParticipate.propTypes = {
  data: PropTypes.object.isRequired,
};

export default WhyParticipate;

export const query = graphql`
  query WhyParticipate {
    whyParticipateEnJson {
      title
      participateTitle
      participateSecTitle
      videoBtn
      videoCodeYT
      participateAlt
      participateImg {
        childImageSharp {
          fluid(maxHeight: 500, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      benefitTitle
      benefitText {
        childMarkdownRemark {
          html
        }
      }
      benefitImg {
        childImageSharp {
          fluid(maxHeight: 1024, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      benefitTabletSubtitle
      benefitTabletSection {
        image {
          childImageSharp {
            fluid(maxHeight: 1024, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        title
        content {
          childMarkdownRemark {
            html
          }
        }
      }
      storiesTitle
      storiesText {
        childMarkdownRemark {
          html
        }
      }
      personalStories
      learnMore
      storiesExternalLink
      storiesAlt
      storiesImg {
        childImageSharp {
          fluid(maxHeight: 500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      seo {
        pageTitle
        pageDescription
      }
    }
  }
`;
