import styled from 'styled-components';

export const PBTContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  width: 100%;
  text-align: center;
  margin-bottom: 40px;
  h2 {
    font-size: 48px;
    color: #10384f;
    font-family: 'HelveticaLTStd-light', 'Helvetica Neue', Helvetica, sans-serif;
    margin: 0 0 28px;
    position: relative;
    padding: 0 0 10px;
    text-align: center;
    line-height: 1.2;
    ::after {
      width: 108px;
      height: 2px;
      background-color: #10384f;
      position: absolute;
      left: 50%;
      bottom: 0;
      margin-left: -54px;
      content: ' ';
    }
  }
  p {
    text-align: center;
    font-size: 18px;
    line-height: 24px;
    color: #10384f;
    font-family: 'Helvetica-LT-Std-roman', 'Helvetica Neue', Helvetica,
      sans-serif;
    margin: 28px 0 15px 0;
  }
  .find-trial-text {
    width: 100%;
  }
  .find-trial-btn {
    display: flex;
    justify-content: center;
    margin-top: 9px;
    word-break: break-word;
  }
  a {
    text-decoration: none;
    font-style: italic;
    border-radius: 0;
    float: right;
    max-width: max-content;
    width: 100%;
    line-height: 1.5;
    color: #ffffff;
    font-size: 14px;
    word-break: break-word;
    font-weight: bold;
    padding: 6px 56px 6px 8px;
    background-color: #66b512;
    border: solid 1px #66b512;
    font-family: 'HelveticaLTStd-BoldObl', 'Helvetica Neue', Helvetica,
      sans-serif;
    text-transform: uppercase;
    position: relative;
    padding-left: 8px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    svg {
      width: 35px;
      height: calc(100% + 2px);
      background-color: #4a8c0f;
      position: absolute;
      right: -1px;
      top: -1px;
      font-size: 26px;
      padding: 4px;
    }
    span {
      ::after {
        width: 14px;
        height: calc(100% + 1px);
        background: linear-gradient(
          to right bottom,
          transparent 49%,
          #4a8c0f 50%
        );
        content: ' ';
        position: absolute;
        right: 34px;
        top: 0;
        margin-top: 0;
        display: block;
      }
    }
  }
  a {
    :hover {
      background-color: #76cd19;
      border: solid 1px #76cd19;
    }
  }
  @media screen and (max-width: 767px) {
    h2 {
      font-size: 32px;
    }
  }
`;
