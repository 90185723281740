import React from 'react';
import PropTypes from 'prop-types';
// import { Row, Col } from 'reactstrap';
import { StaticQuery, graphql } from 'gatsby';
import { IoIosArrowForward } from 'react-icons/io';

//style
import { PBTContainer } from './pbt.css';

const ParticipateBayerTrial = ({ data }) => {
  return (
    <PBTContainer>
      <div
        className="find-trial-text"
        dangerouslySetInnerHTML={{
          __html:
            data.participateBayerTrialJson.content.childMarkdownRemark.html,
        }}
      />
      <div className="find-trial-btn">
        <a href={data.participateBayerTrialJson.redirectLink}>
          {data.participateBayerTrialJson.btnText}
          <span>
            <IoIosArrowForward />
          </span>
        </a>
      </div>
    </PBTContainer>
  );
};

const PBTwithQuery = props => (
  <StaticQuery
    query={graphql`
      query {
        participateBayerTrialJson {
          content {
            childMarkdownRemark {
              html
            }
          }
          btnText
          redirectLink
        }
      }
    `}
    render={data => <ParticipateBayerTrial data={data} {...props} />}
  />
);

export default PBTwithQuery;

ParticipateBayerTrial.propTypes = {
  data: PropTypes.any.isRequired,
};
